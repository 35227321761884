<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <div class="modal" style="z-index:99999;display:block;" v-if="displayQuestionModal">
          <div class="modal-content" style="width: 700px;">
            <div class="close">
              <p class="close_btn">
                <img src="/image/sub/study_close_btn.png" alt="닫기" @click="displayQuestionModal=false"/>
              </p>
            </div>
            <div style="display:block;">
              <p class="modal_quiz_text" style="font-size:30px;color:#784605;padding:50px 0 50px 0;" v-html="writing.content.question"></p>
            </div>
          </div>
        </div>
        <div class="modal" style="z-index:99999;display:block;" v-if="displayCorrectionModal">
          <div class="modal-content" style="width: 700px;">
            <div class="close">
              <p class="close_btn">
                <img src="/image/sub/study_close_btn.png" alt="닫기" @click="displayCorrectionModal=false"/>
              </p>
            </div>
            <div style="display:block;">
              <p class="modal_quiz_text" style="text-align:left;font-size:30px;color:#784605;padding:50px 0 50px 0;" v-html="writing.content.correction_and_remarks"></p>
            </div>
          </div>
        </div>
        <section class="quizbox_area">
          <div class="loading loading--box" v-if="loading"></div>
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Study Room</h2>
          </div>
          <div class="studybox_h3_box">
            <div class="studybox_h3_box_in">
              <img src="/image/common/top_title_wood_bg02.png" style="float:left;"/>
              <div class="study-room-book-header">
                <span class="book-code">{{ study_room.content_name }}</span>
                <h3 class="book-title">{{ study_room.content_title }}</h3>
              </div>
              <img src="/image/common/top_title_wood_bg04.png" style="float:left;"/>
            </div>
          </div>
        <div class="quiz_h4_box">
          <h4>Listening & Reading</h4>
        </div>
          <section class="study_myinfo_list_area" style="height:90%;">
            <div class="mypage_scroll_content">
              <section class="study_quiz_box">
                <div class="writing_title_box">
                  <h4>Writing</h4>
                </div>
                <div>
                  <div class="quiz_question_btn clickable" style="margin-top: -25px;" @click="displayQuestionModal=true"><p data-v-652406fd="" style="padding-left: 50px;">Question</p></div>
                  <div class="quiz_question_box">
                    <textarea class="writing" v-model="writing.content.user_answer"/>
                  </div>
                  <div class="study_result_quiz_num01 clickable" v-if="writing.content.status === null || writing.content.status === 'Return'" @click="submit" style="width: 105px;border-radius: 10px;font-weight: normal;height: 40px;line-height: 38px;">Submit</div>
                  <div class="study_result_quiz_num01 clickable" v-if="writing.content.status === 'Return'" @click="displayCorrectionModal=true" style="width: 145px;border-radius: 10px;font-weight: normal;height: 40px;line-height: 38px;">View Correction</div>
                </div>
              </section>
              <div class="quiz_bottom_btn_box">
                <div class="study_result_quiz_num01 clickable" v-if="back" @click="loadContent(back, talkScheduleId, studyRoomId)">{{ back }}</div>
                <img src="/image/sub/quiz_bottom_arr.png" alt="슬래쉬 아이콘" style="vertical-align:middle;margin:0 10px 0 10px;">
                <div class="study_result_quiz_num01 clickable" v-if="next" @click="loadContent(next, talkScheduleId, studyRoomId)">{{ next }}</div>
              </div>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'study-room.content', params: { talkScheduleId: talkScheduleId } }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import StudyRoom from '@/models/StudyRoom'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  name: 'StudyRoom.Content.Type.Writing',
  data () {
    return {
      study_room: {},
      loading: false,
      displayQuestionModal: false,
      displayCorrectionModal: false,
      writing: {
        content: {
          user_answer: null,
          correction_and_remarks: null,
          question: null,
          status: null
        }
      },
      pagination: {},
      next: null,
      back: null,
      totalPage: 0,
      talkScheduleId: typeof this.$route.params.talkScheduleId !== 'undefined' ? this.$route.params.talkScheduleId : '',
      studyRoomId: typeof this.$route.params.studyRoomId !== 'undefined' ? this.$route.params.studyRoomId : '',
      page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1
    }
  },
  mounted () {
    this.loadContent(1, this.talkScheduleId, this.studyRoomId)
  },
  methods: {
    async loadContent (page, talkScheduleId, studyRoomId) {
      this.loading = true
      const searchParams = {}
      this.page = page
      searchParams.page = page
      this.$router.replace({ query: searchParams })
      const response = await StudyRoom.viewContent({ page: page, talkScheduleId: talkScheduleId, studyRoomId: studyRoomId })
      this.writing = response.data.data[0]
      console.log(this.writing)
      this.pagination = response.data.meta.pagination
      this.next = this.pagination.next
      this.back = this.pagination.back
      this.study_room = response.data.meta.study_room
      this.loading = false
    },
    async submit () {
      const confirm = await this.$swal({
        title: 'Are you sure?',
        text: 'You want to submit!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        className: 'swal2-styled',
        customClass: {
          confirmButton: 'swal2-confirm btn btn-primary swal2-styled',
          cancelButton: 'swal2-cancel swal2-styled'
        },
        buttonsStyling: false
      })
      if (confirm.value) {
        this.writing.content.status = 'Pending'
        StudyRoom.updateContent({ page: this.page, talkScheduleId: this.talkScheduleId, studyRoomId: this.studyRoomId, user_answer: this.writing.content.user_answer, status: this.writing.content.status })
        this.$swal({
          title: 'Successfully!',
          text: 'Submitted',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
